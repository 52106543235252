import React from "react";
import Layout from "../components/Layout";

const Contact = () => (
<Layout>
    <h1>How to Find Me</h1>
    <p>
      Thanks for checking out my website! I hope you enjoy reading 
      my blog posts. If you have any ideas for future posts, ways I can
      improve my content, or just want to talk, feel free to get in touch!
    </p>
    <p>
        I can be found on  <a href="https://github.com/DigitalTorterra/">GitHub</a>, <a 
        href="www.linkedin.com/in/patrick-faley-23a421154">Linkedin</a>, or you can
        email me at <a href="mailto:DigitalTorterra@gmail.com">DigitalTorterra@gmail.com</a>.
    </p>
</Layout>
);

export default Contact;
